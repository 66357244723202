import React, { useState,  } from 'react'
import vector1 from "../../assets/images/vector4.webp";
import vector2 from "../../assets/images/vector5.webp";
import vector3 from "../../assets/images/vector6.webp";
import vector4 from "../../assets/images/vector7.webp";
import vector5 from "../../assets/images/vector8.webp";
import vector6 from "../../assets/images/vector9.webp";
const solutions = [
	{
		title: 'Full Medical Billing',
		description: 'Maximizing Reimbursement: Our team works tirelessly to ensure you receive the maximum reimbursement for your services.',
		img: vector1
	},
	{
		title: 'Account Receivables',
		description: 'Efficient Collections: Employing accurate and effective tactics to optimize your medical accounts receivable collection ratio.',
		img: vector2
	},
	{
		title: 'Medical Coding',
		description: 'Streamlined Compliance: Identifying opportunities for process improvement to maintain a current, comprehensive, and compliant Charge Description Master (CDM).',
		img: vector3
	},
	{
		title: 'Patient Care Coordinator',
		description: 'Insurance Verification Excellence: Ensuring eligibility and benefits verification is meticulously executed to directly impact your reimbursements.',
		img: vector4
	},
	{
		title: 'Credentialing',
		description: 'Navigating Complexity: Trust our experts to guide you through the intricate process of provider enrollment and medical credentialing.',
		img: vector5
	},
	{
		title: 'Consulting',
		description: 'Unlimited Support: Access top-tier consulting services to keep your practice at the forefront of industry best practices.',
		img: vector6
	}
]


function Services() {
	const [show, setShow] = useState(false);
	const handleShow = () => setShow(true);

	return (
		<div>
			<div className="services">
				<div className="services_section">
					<h2>OUR SOLUTIONS</h2>
					<p>
						At MIAMI BEACH Medical Billing, we excel in the field of medical billing. Our solutions are designed to streamline and optimize the complex world of healthcare revenue management. We offer a comprehensive suite of services, all aimed at maximizing your reimbursement potential and ensuring the financial health of your practice. With a focus on precision, compliance, and efficiency, our Medical Billing services are second to none.
					</p>
				</div>

				<ul className="services_cards row">
					{solutions.map((x,i) =>
						<div key={i} className='col-md-5'>
							<div className="services_cards_row_1 mb-3">
								<div className="services_cards_inner">
									<img src={x.img} alt="Full-Medical Billing" />
									<div className="inner_section">
										<li>{x.title}</li>
										<p>{x.description}</p>
									</div>
								</div>
							</div>
						</div>
					)}
				</ul>
				<div className="btn_wrapper">
					<button className="btn_10" onClick={handleShow}>
						<span>Contact us </span>
					</button>
				</div>

			</div>
		</div>
	)
}

export default Services