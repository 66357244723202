import "./App.css";
import "./assets/style.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./pages/components/Homepage";
import SigninFunction from "./pages/signing";
function App() {
	return (
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<Routes >
				<Route path="/form" element={<SigninFunction />} />
				<Route exact path="/" element={<Homepage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
