import React, { useState, useRef } from 'react'
import Pic3 from "../../assets/images/10.webp";
import Pic2 from "../../assets/images/mbmb-white.webp";
import Vector1 from "../../assets/images/Vector1.webp";
import Vector2 from "../../assets/images/Vector2.webp";
import Vector3 from "../../assets/images/Vector3.webp";
import envelope from "../../assets/images/envelope.webp";
import phone from "../../assets/images/phone.webp";
import ModalForm from './ModalForm';


function Header() {
	const handleShow = () => { setShow(true) };
	const [show, setShow] = useState(false);

	return (
		<div>
			<div className="top_header_portion">
				<div className="top_section_pic">
					<img src={Pic2}  alt="Miami Beach Medical Billing" />
				</div>

				<div className="header_sections row">
					<div className="left_section col-md-5">
						<h1>MIAMI BEACH Medical Billing</h1>
						<p>
							<img src={Vector1} alt="Miami Beach Medical Billing" /> To provide maximum reimburesement
							for our clients.
						</p>
						<p className="smll-widht">
						At MIAMI BEACH Medical Billing, our mission is clear: to provide maximum reimbursement for our clients. We understand the complexities of medical billing, coding, and revenue management, and our team of experts is here to help you navigate these challenges. With a focus on precision, compliance, and efficiency, we offer a comprehensive suite of services tailored to meet your specific needs.
						</p>
						{/* <form> */}
						<button className="button btn_1" onClick={handleShow}>
							<span>FREE CONSULTATION</span>
						</button>
						{/* {Click ? <Model /> : null} */}
						{/* </form> */}
					</div>

					<div className='col-md-5'>
						<div
							style={{ backgroundImage: `url(${Pic3})` }}
							className="right_section">
							{/* <img
								src={Pic3}
								alt=""
								style={{
									
								}}
							/> */}

							<div className="circle1">
								<div className="main-card1">
									<div className='right_section_card_one'>
										<div className="card1">
											<img src={Vector2} alt="Medical Coding" />
											<h3>Medical Coding</h3>
											<p>
												Identify opportunities for process improvement in order to
												maintain a current, comprehensive and compliant CDM
											</p>
										</div>
									</div>
									<div className='right_section_card_two'>
										<div className="card1 card2">
											<img src={Vector3} alt="Full-Medical Billing" />
											<h3>Full-Medical Billing</h3>
											<p>To provide maximum reimburesement for our clients</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='mt-5 mb-3 contact-container'>
							<div className='contact-email-cotainer'>
								<img src={envelope} height='16px' width='19px' />
								<h3 className='btn' onClick={()=>window.location.href = `mailto:Jasmel@medicalbillingmb.com`} style={{fontSize:'24px'}} type='email'>
								Jasmel@medicalbillingmb.com
								</h3>
							</div>
							<div className='contact-email-cotainer'>
								<img src={phone} height='19px' width='19px' />
								<h3 className='btn' onClick={()=>window.open('tel://+1-786-643-2099', "_blank")} type="tel" style={{fontSize:'24px'}} >+1-786-643-2099</h3>

							</div>
						</div>
					</div>
				</div>
			</div>
			<ModalForm show={show} />

		</div>
	)
}

export default Header