import React, { useState, } from "react";
import Account1 from "../../assets/images/recievables.webp";
import Account2 from "../../assets/images/account2.webp";
import LeftReceivable from "../../assets/images/left_receivable.webp";
import RightReceivable from "../../assets/images/right_receivable.webp";
import First from "../../assets/images/first.webp";
import Second from "../../assets/images/second.webp";
import Third from "../../assets/images/third.webp";
import Footer from "../../assets/images/mbmb-black.webp";
import FooterLink from "../../assets/images/122.webp";
import Group from "../../assets/images/Group.webp";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ModalForm from "./ModalForm";
import emailjs from "@emailjs/browser";
import Services from "./Services";
import Practices from "./Practices";
import MedicalBilling from "./MedicalBilling";
import Header from "./Header";
import { Helmet } from "react-helmet";

const Homepage = () => {
	const [first_name, setFirstName] = useState("");
	const [last_name, setLastName] = useState("");
	const [user_email, setUserEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [Company_Name, setCompanyName] = useState("");
	const [done, setdone] = useState(false)
	const closeForm = () => {
		// setShow(false);
		alert("Email Sent");
	};
	const sendEmail = (e) => {
		e.preventDefault();
		console.log(first_name + last_name + user_email + phone + Company_Name);
		const emailContent = {
			first_name: first_name,
			last_name: last_name,
			user_email: user_email,
			phone: phone,
			Company_Name: Company_Name,
		};
		emailjs
			.send(
				"service_v6wnbbl",
				"template_82c6idj",
				emailContent,
				"bVE_LXLVlDm2Y3Rql"
			)
			.then(
				(result) => {
					toast.success("Success Notification !", {
						position: toast.POSITION.TOP_RIGHT,
					});
					console.log(result.text);
					console.log("sent");
					setdone(true);
					setCompanyName();
					setFirstName();
					setLastName();
					setPhone();
					setUserEmail();
				},
				(error) => {
					console.log(error.text);
				}
			);
	};
	// after
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const style = {
		back_body: {
			// background: `linear-gradient(90deg, #d5278f 0%, #2f3d95 95.61%)`,
		},
		hadear_style: {
			// background: `linear-gradient(90deg, #d5278f 0%, #2f3d95 95.61%)`,
			// elevation: 0,
			// shadowOpacity: 0,
			// borderBottomWidth: 0,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			padding: '0px',
			gap: '10px',


			// paddingLeft: "300px",
		},
		filed_sty: {
			backgroundColor: "transparent",
			borderColor: "transparent",
			borderBottom: "1px solid #ffffff",
			color: "#ffffff",
			borderRadius: '0px',

		},


		button_styl: {
			width: "100%",
			height: "59px",
			background: "#ffffff",
			borderRadius: "10px",
			color: "#b868ab",
			fontFamily: "Montserrat",
			fontStyle: "normal",
			fontWeight: "600",
			fontSize: "22px",
			lineHeight: "160%",
		},
	};

	return (
		<>

			<Helmet>
				<meta charSet="utf-8" />
				<title>Miami Beach Billing Company - MBMB</title>
				<meta name="description" content="We offer medical coding outsourcing, hospital billing, dispute medical services, and insurance billing consultancy. Let us help you streamline your medical billing and coding process and increase your revenue. Contact us today." />
				<meta name="keywords" content="Medical Billing, Coding assistant, Billing Consultant, Medical coding outsourcing, Biller and Coder, Billing Specialist, Doctor credential services, Hospital billing, Dispute Medical, Phycian, Coding Specialist, Insurance Billing Consultants," />

			</Helmet>
			<div className="web_page">
				<Header />
				<Services />

				<MedicalBilling />
				<Practices />
				<div className="accounts">
					{/* <div className="accounts_grid row"> */}
					{/* <div className="col-md-6"> */}

					<div className="account_text" style={{ backgroundImage: `url(${Account1})` }}>
						<span className="mydiv" >

							<h3>Accounts Receivables</h3>
							<p>
								Are you struggling with managing your healthcare accounts receivable? Our expert team of Billing Consultants and Coding Assistants can help! We specialize in healthcare revenue management and will work tirelessly to resolve your outstanding accounts receivables. With our proven approach, we only get paid when you do, and our objective is to maintain account receivable according to MGMA standards. Let us take care of your revenue management, so you can focus on providing top-quality patient care.
							</p>
							<div className="btn_wrapper">
								<button className="btn_11 mb-3" onClick={handleShow}>
									<span>Free Consultation </span>
								</button>
							</div>
						</span>
						{/* </div> */}
						{/* </div> */}
					</div>

					<div className="receivable">
						<h3>Accounts Receivables</h3>
						<div className="receivable_grid row">
							<div className="left_receivable col-md-5">
								<img src={LeftReceivable} alt="Verification of Eligibility & Benefits" />
								<h4>Verification of Eligibility & Benefits</h4>
								<p>
									Ensure you never get denied for lack of coverage or benefits again with our expert team of Billing Consultants, Coding Assistants, and Billing Specialists. We understand that eligibility and benefits verification plays a pivotal role in reimbursement, especially for physicians in dispute medical cases. That's why we offer thorough eligibility checks to ensure patients are covered by their plan and to identify any patient liabilities, such as copays, deductibles, or coinsurance. By verifying benefits ahead of appointments, we give you the opportunity to collect copays at the time of service and inform patients about potential liabilities. Don't let lack of coverage or benefits hold you back - trust our team to optimize your revenue and patient care.
								</p>
							</div>

							<div className="right_receivable col-md-5">
								<img src={RightReceivable} alt="Prior Authorizations" />
								<h4>Prior Authorizations</h4>
								<p>
									Stay ahead of service denials with our expert team of Medical Billing and Coding Specialists, Billing Consultants, and Insurance Billing Consultants. We understand the importance of obtaining necessary authorizations to proactively cater to denials associated with services that require pre-approval by insurers. Insurance companies regularly make policy changes and updates in their health plans, so it's essential to check if the patient is covered by the plan for a particular service and if it requires prior authorization before it is rendered. Our team will ensure that your authorizations are on file and validated well in advance of the patient's appointment. Trust us to provide top-notch medical coding outsourcing, doctor credential services, hospital billing, and dispute medical resolution services, so you can focus on delivering quality care to your patients.
								</p>
							</div>
						</div>
						<div className="btn_wrapper">

							<button className="btn_4" onClick={handleShow}>
								<span>Free Consultation</span>
							</button>
						</div>
					</div>
				</div>

				<div className="medical_coding">
					<div className="medical_width">
						<h1>Medical Coding</h1>
						<p>
							Ensure accurate coding for your practice with our team of certified Medical Billing and Coding Specialists, Billing Consultants, and Coding Specialists. Our team has expertise in all specialties and adheres to industry guidelines and standards to meet your medical coding needs. We offer medical coding outsourcing services and provide support for doctor credential services, hospital billing, dispute medical resolution services, and insurance billing consultancy. Trust us to optimize your revenue cycle management and ensure that you receive the reimbursement you deserve.
						</p>
						<div className="medical_icons">
							<div className="first_icon">
								<img src={First} alt="ICD Coding" />
								<h5>ICD Coding</h5>
							</div>

							<div className="first_icon">
								<img src={Second} alt="CPT/HCPCS Coding" />
								<h5>CPT/HCPCS Coding</h5>
							</div>

							<div className="first_icon">
								<img src={Third} alt="Modifiers" />
								<h5>Modifiers</h5>
							</div>
						</div>
						<div>

						</div>
						<div className="btn_5_wrapper">
							<button className="btn_5 " onClick={handleShow}>
								<span>Contact us </span>
							</button>
						</div>
					</div>
				</div>
				<div style={{ marginTop: '40px' }}></div>
				<div className="Credentialing row">
					<div className="left_Credentialing col-md-5 mb-3">
						<h1>Credentialing</h1>
						<p>
							Leave your credentialing worries to us! Our team of Doctor Credential Services and Billing Consultants will handle the complex credentialing process for you. We have experience across all specialties and payers, including governmental and private insurers. Our credentialing specialists will file your application with complete detail and pursue it until it's approved and you're enrolled on the first attempt. In addition to our credentialing services, we also provide support for hospital billing, medical billing, and insurance billing consultancy. Trust us to optimize your revenue cycle management and ensure that you receive the reimbursement you deserve.
						</p>

						<ul>
							<li>Attestation due for private insurers</li>
							<li>Demographic changes including address</li>
							<li>CAQH Enrollment and Maintenance</li>
							<li>Electronic Data Interchange (EDI)</li>
							<li>Electronic Remittance Advice (ERA)</li>
							<li>Electronic Funds Transfer (EFT)</li>
						</ul>

						<button className="btn_6" onClick={handleShow}>
							<span>Free Consultation </span>
						</button>
					</div>

					<div
						// style={{ backgroundImage: `url(${Group})` }} 
						className="right_Credentialing col-md-5"
					>
						<img src={Group} alt="Free Consultation" style={{ height: '80%', width: '100%' }} />
					</div>
				</div>

				<div className="contact">
					<div className="contact_section">
						<p>Contact Us</p>
						<h1>Email us Now and Get the Job Done</h1>
					</div>
					<div className="search_items">
						<input type="email" placeholder="Enter your email" name="user_email"
							onChange={(e) => {
								setUserEmail(e.target.value);
							}} />
						<button className="btn search_btn" onClick={sendEmail}>
							<span>Let's Email You</span>
						</button>
					</div>
				</div>
				<div className="divider"></div>
			</div>

			<div className="footer_part">
				<div className="footer_part_pic">
					<img src={Footer} alt="Instagram Link" style={{ width: '200px', height: 'auto' }} />
					<ul className="social_media">
						<li>
							<a href="https://www.instagram.com/Miamitesting123" rel="nofollow">
								<img
									src={FooterLink}
									alt="MBMB"
									style={{ width: "40px", borderRadius: "12px" }}
								/>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div className='custom-span' >
				Contributed By <a target='_blank' href='https://www.myababeel.com' rel='' ><img src='https://myababeel.com/ababeel-logo-transparent.webp' className='inline-block' width={50} height={39} /> <b > MyAbabeel</b></a>
			</div>
			<ModalForm show={show} />
		</>
	);
};

export default Homepage;
