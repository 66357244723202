import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import tick from "../../assets/images/tick.webp";

const style = {
  back_body: {
    // background: `linear-gradient(90deg, #d5278f 0%, #2f3d95 95.61%)`,
    padding:'0px',
    marginLeft:'20px',
    marginRight:'20px'
  },
  hadear_style: {
    // background: `linear-gradient(90deg, #d5278f 0%, #2f3d95 95.61%)`,
    // elevation: 0,
    // shadowOpacity: 0,
    // borderBottomWidth: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
    gap: "10px",
    background: 'linear-gradient(90deg, #D5278F 0%, #2F3D95 95.61%)'

    // paddingLeft: "300px",
  },
  filed_sty: {
    backgroundColor: "transparent",
    borderColor: "transparent",
    borderBottom: "1px solid #ffffff",
    color: "#ffffff",
    borderRadius: "0px",
    margin:'0px'
  },

  button_styl: {
    width: "100%",
    height: "59px",
    // background: "#ffffff",
    borderRadius: "10px",
    color: "#b868ab",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "120%",
  },
};

function ModalForm(props) {
  const [first_name, setFirstName] = useState();
  const [last_name, setLastName] = useState();
  const [user_email, setUserEmail] = useState();
  const [phone, setPhone] = useState();
  const [Company_Name, setCompanyName] = useState();
  const { show } = props;
  const [done, setdone] = useState(false)
  const closeForm = () => {
    // setShow(false);
    alert("Email Sent");
  };
  const sendEmail = (e) => {
    e.preventDefault();
    console.log(first_name + last_name + user_email + phone + Company_Name);
    const emailContent = {
      first_name: first_name,
      last_name: last_name,
      user_email: user_email,
      phone: phone,
      Company_Name: Company_Name,
    };
    emailjs
      .send(
        "service_v6wnbbl",
        "template_82c6idj",
        emailContent,
        "bVE_LXLVlDm2Y3Rql"
      )
      .then(
        (result) => {
          toast.success("Success Notification !", {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log(result.text);
          console.log("sent");
          setdone(true);
          setCompanyName();
          setFirstName();
          setLastName();
          setPhone();
          setUserEmail();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      size="lg"
      centered
      className="modal-container"
      // style={{ width:'auto',minWidth:'550px',maxWidth:'80vw',overflow:'auto',padding:'25px'}}
    >
      <Modal.Header style={style.hadear_style}>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="model-hading"
        >
          FREE CONSULTATION
        </Modal.Title>
        <p className="model-paragraph">
          Please leave us your information and the account manager will contact
          you asap
        </p>
      </Modal.Header>
      <Modal.Body style={style.back_body}>
        <Form
        // ref={form}
        // onSubmit={sendEmail}
        >
          <Row>
            <Col xs={6} md={6}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <input
                  // className={styles.input_field}
                  placeholder="First Name"
                  type="text"
                  style={style.filed_sty}
                  name="first_name"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={6} md={6}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <input
                  // className={styles.input_field}
                  placeholder="Last Name"
                  type="text"
                  style={style.filed_sty}
                  name="last_name"
                  onChange={(e) => {
                    setLastName(e.target.value);

                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <input
              // className={styles.input_field}
              placeholder="Email"
              type="email"
              style={style.filed_sty}
              name="user_email"
              onChange={(e) => {
                setUserEmail(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <input
              // className={styles.input_field}
              placeholder="Phone No"
              type="text"
              style={style.filed_sty}
              name="phone"
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <input
              // className={styles.input_field}
              placeholder="Company Name"
              type="text"
              name="Company_Name"
              style={style.filed_sty}
              onChange={(e) => {
                setCompanyName(e.target.value);
              }}
            />
          </Form.Group>
          <Row>
            <Col xs={6} md={6}>
              <Form.Group
                className="ms-auto"
                controlId="exampleForm.ControlInput1"
              >
                <input
                  type="submit"
                  value="Close"
                  onClick={props.onHide}
                  style={style.button_styl}
                />
              </Form.Group>
            </Col>

            <Col xs={6} md={6}>
              {done ?
                <Form.Group
                  className="ms-auto"
                  controlId="exampleForm.ControlInput1"
                >
                  <input
                    type="submit"
                    value="🗸"
                    onClick={sendEmail}
                    style={style.button_styl}
                  />
                </Form.Group>
                : <Form.Group
                  className="ms-auto"
                  controlId="exampleForm.ControlInput1"
                >
                  <input
                    type="submit"
                    value="Send"
                    onClick={sendEmail}
                    style={style.button_styl}
                  />
                </Form.Group>
              }
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ModalForm;
